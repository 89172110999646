import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";

// ##### Public ##### \\
import Homepage from "./pages/Public/Homepage";
import RestrictedRoute from "./components/Protected/RestrictedRoute";
import Storefront from "./pages/Storefront/Storefront";
// import Checkout from "./pages/Checkout/Checkout";
import SingleCheckout from "./pages/Checkout/SingleCheckout";
import Error404 from "./pages/Error/Error404";

const PaymentSuccess = lazy(() => import("./pages/Checkout/PaymentSuccess"));

// ##### Logged in ##### \\
const Login = lazy(() => import("./pages/Public/Login"));

// Admins
const Admins = lazy(() => import("./pages/Admins/Admins"));
const AddAdmin = lazy(() => import("./pages/Admins/AddAdmin"));
const EditAdmin = lazy(() => import("./pages/Admins/EditAdmin"));

// My Profile
const MyAccount = lazy(() => import("./pages/me/MyAccount"));

// Products
const Products = lazy(() => import("./pages/Products/Products"));
const AddProduct = lazy(() => import("./pages/Products/AddProduct"));
const Product = lazy(() => import("./pages/Product/Product"));

// Price plans
const Prices = lazy(() => import("./pages/Prices/Prices"));

// Price plans of product
const Price = lazy(() => import("./pages/Product/Price"));
const AddPrice = lazy(() => import("./pages/Product/AddPrice"));
const EditPrice = lazy(() => import("./pages/Product/EditPrice"));

// Coupons
const AllCoupons = lazy(() => import("./pages/Coupons/Coupons"));

// Coupons of the price plans
const Coupons = lazy(() => import("./pages/Coupon/Coupons"));
const AddCoupon = lazy(() => import("./pages/Coupon/AddCoupon"));
const Coupon = lazy(() => import("./pages/Coupon/Coupon"));

// Webhooks
const Webhooks = lazy(() => import("./pages/Webhooks/Webhooks"));
const Webhook = lazy(() => import("./pages/Webhook/Webhook"));
const AddWebhook = lazy(() => import("./pages/Webhook/AddWebhook"));

// Students
const Students = lazy(() => import("./pages/Students/Students"));
const AddStudent = lazy(() => import("./pages/Students/AddStudent"));
const Student = lazy(() => import("./pages/Student/Student"));
const StudentOrders = lazy(() => import("./pages/Student/StudentOrders"));

// Orders of students
const Orders = lazy(() => import("./pages/Orders/Orders"));

// Orders of student
const OrderInfo = lazy(() => import("./pages/Student/OrderInfo"));
const AddOrder = lazy(() => import("./pages/Student/AddOrder"));

// Order items of student
const ItemInfo = lazy(() => import("./pages/Item/ItemInfo"));
const ChangeOrderItem = lazy(() => import("./pages/Student/ChangeOrderItem"));
const RefundOrderItem = lazy(() => import("./pages/Student/RefundOrderItem"));
const CancelOrderItem = lazy(() => import("./pages/Student/CancelOrderItem"));

// Reports
const Reports = lazy(() => import("./pages/Report/Reports"));

// Settings
const Settings = lazy(() => import("./pages/Settings/Settings"));
const Gateways = lazy(() => import("./pages/Settings/Gateways"));

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route path='/' element={<Homepage />} />

        <Route index element={<Storefront />} />
        <Route path='/checkout/:price_slug' element={<SingleCheckout />} />
        {/* <Route path='checkout' element={<Checkout />} /> */}
        <Route path='success' element={<PaymentSuccess />} />

        <Route path='/login' element={<Login />} />

        {/* Owners && Admins only */}
        <Route element={<RestrictedRoute />}>
          <Route path='/admins'>
            <Route index path='' element={<Admins />} />
            <Route path='new' element={<AddAdmin />} />
            <Route path=':admin_id' element={<EditAdmin />} />
          </Route>

          <Route path='/my-account' element={<MyAccount />} />

          {/* All students */}
          <Route path='/students'>
            <Route index path='' element={<Students />} />
            <Route path='new' element={<AddStudent />} />
            <Route path=':student_id' element={<Student />} />

            {/* Orders of student */}
            {/* All orders of the students */}
            <Route path=':student_id/orders' element={<StudentOrders />} />
            <Route path=':student_id/orders/new' element={<AddOrder />} />
            <Route
              path=':student_id/orders/:order_id'
              element={<OrderInfo />}
            />

            {/* Order items of student */}
            <Route
              path=':student_id/orders/:order_id/items/:item_id'
              element={<ItemInfo />}
            />
            <Route
              path=':student_id/orders/:order_id/items/:item_id/change'
              element={<ChangeOrderItem />}
            />

            <Route
              path=':student_id/orders/:order_id/items/:item_id/refund'
              element={<RefundOrderItem />}
            />

            <Route
              path=':student_id/orders/:order_id/items/:item_id/cancel'
              element={<CancelOrderItem />}
            />
          </Route>

          {/* All orders */}
          <Route path='orders' element={<Orders />} />

          {/* All products */}
          <Route path='/products'>
            <Route index path='' element={<Products />} />
            <Route path='new' element={<AddProduct />} />
            <Route path=':product_id' element={<Product />} />

            {/* Price plans of products */}
            <Route path=':product_id/prices' element={<Price />} />
            <Route path=':product_id/prices/new' element={<AddPrice />} />
            <Route
              path=':product_id/prices/:price_id'
              element={<EditPrice />}
            />

            {/* Coupon */}
            {/* All coupons of the price plans */}
            <Route
              path=':product_id/prices/:price_id/coupons'
              element={<Coupons />}
            />
            <Route
              path=':product_id/prices/:price_id/coupons/new'
              element={<AddCoupon />}
            />

            <Route
              path=':product_id/prices/:price_id/coupons/:coupon_id'
              element={<Coupon />}
            />
          </Route>

          {/* All price plans */}
          <Route path='prices' element={<Prices />} />

          {/* All coupons */}
          <Route path='coupons' element={<AllCoupons />} />

          {/* Reports */}
          <Route path='reports' element={<Reports />} />

          {/* Settings */}
          <Route path='/settings'>
            <Route index path='' element={<Settings />} />
            <Route path='gateways' element={<Gateways />} />
          </Route>

          {/* All Webhooks */}
          <Route path='/webhooks'>
            <Route index path='' element={<Webhooks />} />
            <Route path=':webhook_id' element={<Webhook />} />
            <Route path='new' element={<AddWebhook />} />
          </Route>
        </Route>

        <Route path='*' element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
