import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Link, useParams } from "react-router-dom";
import CartItems from "./CartItems";
import { retrieveUser } from "../../utils/localStorageFn";

function StoreNavigation() {
  const { store } = useParams();
  const { user } = retrieveUser();

  return (
    <Navbar bg='dark' data-bs-theme='dark' sticky='top'>
      <Container>
        <Nav className='me-auto'>
          <Navbar.Brand href={`/`} className='bg-transparent shadow-none'>
            Piranha Profits
          </Navbar.Brand>

          {user &&
            ["owner", "primary admin", "secondary admin", "va"].includes(
              user.role
            ) && (
              <Link to={`/students`} className={"nav-link"}>
                Admin Dashboard
              </Link>
            )}
        </Nav>
        {/* <CartItems /> */}
      </Container>
    </Navbar>
  );
}

export default StoreNavigation;
