import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Elements } from "@stripe/react-stripe-js";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { GetCountries, GetState } from "react-country-state-city";
import {
  emailValidator,
  emailWithConPatternValidator,
} from "../../utils/validationFn";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import CardElement from "../Stripe/CardElement";
import { extractEmailFromURL } from "../../utils/extractURLParams";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { removeItem } from "../../store/cartSlice";
import { NumericFormat } from "react-number-format";
import EncryptionIcon from "./EncryptionIcon";

const AlertModal = ({ show, onClose, children }) => {
  const handleClose = () => {
    onClose(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size='lg'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          size='lg'
          style={{ border: "#3CAD66", backgroundColor: "#3CAD66" }}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// load only stripe publishable key
const loadStripePublishableKey = async (accounts) => {
  if (!accounts.length)
    return NotificationManager.warning("No payment gateway found.");

  const stripeAccount = accounts.find((account) => account.vendor === "stripe");
  if (!stripeAccount) return;

  stripeAccount.load_stripe = await loadStripe(
    stripeAccount.stripe_publishable_key
  );

  return stripeAccount;
};

const PaymentForm = ({
  cart: selectedPlans,
  coupons: appliedCoupons,
  removeCoupons,
  utm,
  grand_total,
  disabled,
}) => {
  const { price_slug } = useParams();

  // Payment information
  const min_phone_length = 3;
  const max_phone_length = 25;

  const [isLoading, setIsLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const stateRef = useRef();
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [paymentVendor, setPaymentVendor] = useState(null);
  const [paymentAccountId, setPaymentAccountId] = useState(null);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [ccSettings, setCCSettings] = useState([]);
  const [isRequiredCC, setIsRequiredCC] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // these two variables are being used to submit with the payment
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);

  const paymentObj = {
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    country_iso2: "",
    state: "",
    phone_code: "",
    phone_number: "",
    authorized: false,
    authorized_at: new Date(),
    user_agent: navigator.userAgent,
  };

  const [obj, setObj] = useState(paymentObj);
  const [isEmailConPatter, setIsEmailConPatter] = useState(false);

  // Purpose: to display error message on the field if there is any
  const [isTouched, setIsTouched] = useState({
    name: false,
    firstname: false,
    lastname: false,
    email: false,
    phone_number: false,
  });

  // Purpose: to enable submit button
  // include only items to be validated
  const [isValid, setIsValid] = useState({
    name: false,
    firstname: false,
    lastname: false,
    email: false,
    country: false,
    state: false,
    phone_number: false,
    authorized: false,
  });

  const fetchPaymentAccounts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/payment_accounts`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const { error, payloads } = await response.json();
    if (error) {
      const { message } = error;
      throw new Error(message);
    }

    setPaymentAccounts(payloads);
  };

  // Get payment publishable key
  useEffect(() => {
    fetchPaymentAccounts();
  }, []);

  // Populate country lists
  useEffect(() => {
    GetCountries().then((results) => {
      const countryLists = results.map((result, index) => ({
        index: index,
        value: result.id,
        label: `${result.emoji} ${result.name}`,
        name: result.name,
        iso2: result.iso2,
        iso3: result.iso3,
        phone_code: `+ ${result.phone_code}`,
      }));

      setCountryOptions(countryLists);
    });
  }, []);

  // manage vanilla html
  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value ? value : "";

    let isValid = false;
    if (name === "email") {
      inputValue = inputValue.toLowerCase();

      if (emailWithConPatternValidator(inputValue)) {
        isValid = false;
        setIsEmailConPatter(true);
      } else {
        setIsEmailConPatter(false);
        isValid = emailValidator(inputValue);
      }
    } else {
      isValid = Boolean(value);
    }

    setObj((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      [name]: isValid,
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    setIsTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  // manage react-select
  const handleSelectChange = (selectOptions, name) => {
    if (name === "country") {
      if (stateRef.current) {
        stateRef.current.clearValue();
      }

      setObj((prevState) => ({
        ...prevState,
        country_iso2: selectOptions.iso2,
        country: selectOptions.name,
        phone_code: selectOptions.phone_code,
        state: "",
      }));

      // Manage countries / region with states but is okay to not collect
      const excluded_lists = ["Singapore"];
      if (excluded_lists.includes(selectOptions.name)) {
        setStateOptions([]);

        setIsValid((prevState) => ({
          ...prevState,
          country: Boolean(selectOptions.name),
          state: true,
        }));

        return;
      }

      setIsValid((prevState) => ({
        ...prevState,
        [name]: Boolean(selectOptions.name),
      }));

      // populate state lists
      GetState(selectOptions.value).then((results) => {
        const stateLists = results.map((result, index) => {
          return {
            index: index,
            value: result.id,
            label: result.name,
            name: result.name,
            state_code: result.state_code,
          };
        });

        // Manage countries / region without state
        // set the state to valid so that submit button can be enable
        if (!stateLists.length) {
          setStateOptions([]);

          setIsValid((prevState) => ({
            ...prevState,
            state: true,
          }));

          return;
        }

        // Manage countries / region with state
        // set the state to invalid so that submit button can be disable
        setStateOptions(stateLists);
        setIsValid((prevState) => ({
          ...prevState,
          state: false,
        }));
      });
    } else if (name === "state") {
      // when change country, state wil return null
      // Break out of loop immediately
      // Otherwise, it will throw error.
      if (!selectOptions) return;

      setObj((prevState) => ({
        ...prevState,
        state: selectOptions.name,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        [name]: Boolean(selectOptions.name),
      }));
    } else {
      setObj((prevState) => ({
        ...prevState,
        [name]: Array.isArray(selectOptions)
          ? selectOptions
          : selectOptions.value,
      }));
    }
  };

  const cardElementChangeHandler = ({ stripe, elements }) => {
    setStripe(stripe);
    setElements(elements);
  };

  // Functions
  // manage react-number-format
  const handleNumericChange = (val, name) => {
    const { floatValue, formattedValue } = val;

    if (name === "phone_number") {
      setObj((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        [name]: formattedValue.toString().length >= min_phone_length,
      }));

      return;
    }

    const value = parseInt(Math.round(floatValue));

    setObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      [name]: Boolean(value),
    }));
  };

  const handleNumericBlur = (name) => {
    setIsTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleCheck = (e) => {
    setObj((prevState) => ({
      ...prevState,
      authorized: e.target.checked,
      authorized_at: new Date(),
    }));

    setIsValid((prevState) => ({
      ...prevState,
      authorized: e.target.checked,
    }));
  };

  const handleSelectPaymentType = (e, data) => {
    setPaymentVendor(data.vendor);
    setPaymentAccountId(data.id);

    if (data.vendor === "stripe") {
      createStripeCheckoutIntent();
    }
  };

  // Modal
  let alertElement;
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleClose = () => {
    setShow(false);
    setModalContent(null);
  };

  useEffect(() => {
    if (!paymentAccounts.length) return;

    if (grand_total === 0) {
      const firstPaymentAccount = paymentAccounts[0];
      setPaymentVendor(firstPaymentAccount?.vendor);
      setPaymentAccountId(firstPaymentAccount?.id);
    }
  }, [grand_total]);

  // Submit payment to server for processing
  const paymentHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!paymentAccounts.length) {
      NotificationManager.warning(
        "Oops...no payment gateway is currently active. Please try again later."
      );
      return;
    }

    if (paymentVendor === "toyyibpay") {
      const { error, status, payloads } =
        await createBankTransferCode().finally(() => setIsLoading(false));

      if (error) {
        const { message } = error;
        NotificationManager.error(message);
        return;
      }

      if (status === "PLANS_REMOVED") {
        const removed_items = [];
        payloads.map((removed_item) => {
          removed_items.push(removed_item.name);
          dispatch(removeItem(removed_item));
        });

        if (price_slug) {
          NotificationManager.info(
            `${String(removed_items)} ${
              removed_items.length > 1 ? "have" : "has"
            } been unpublished. Please consider to explore other memberships.`
          );

          setTimeout(() => {
            navigate(`/`);
          }, 1500);

          return;
        }

        NotificationManager.info(
          `${String(removed_items)} ${
            removed_items.length > 1 ? "have" : "has"
          } been unpublished and thus removed from your cart. Please review and complete your purchase again.`
        );

        return;
      }

      if (status === "DISQUALIFIED_COUPONS") {
        const not_permitted_coupon_ids = [];
        const not_permitted_plans = [];

        payloads.map((coupon) => {
          not_permitted_plans.push(coupon.plan);
          not_permitted_coupon_ids.push(coupon.coupon_id);
        });

        removeCoupons(not_permitted_coupon_ids);

        const itemsElement = not_permitted_plans.map((plan, index) => {
          return <li key={index}>{plan}</li>;
        });

        alertElement = (
          <div>
            <p>
              This coupon code does not applicable to the following cart items
              for your account. Thus, has been removed from the particular cart
              items. Please review and complete your purchase again.
            </p>
            <ul>{itemsElement}</ul>
          </div>
        );

        setShow(true);
        setModalContent(alertElement);

        return;
      }

      if (status === "CART_GRAND_TOTAL_ZERO") {
        window.location.replace(
          `/success?ppay_intent_client_secret=${payloads?.ppayIntent}`
        );
        return;
      }

      if (status === "TOYYIBPAY_PAYMENT_CHECKOUT_INTENT") {
        const { BillCode } = payloads;
        window.location.href = `${process.env.REACT_APP_TOYYIBPAY_ENDPOINT}/${BillCode}`;
        return;
      }

      if (status === "NOTHING") {
        console.log(".");
        return;
      }
    }

    if (paymentVendor === "stripe") {
      const {
        error: updateError,
        status,
        payloads,
      } = await updateStripeCheckoutIntent().finally(() => setIsLoading(false));

      if (status === "PLANS_REMOVED") {
        const removed_items = [];
        payloads.map((removed_item) => {
          removed_items.push(removed_item.name);
          dispatch(removeItem(removed_item));
        });

        if (price_slug) {
          NotificationManager.info(
            `${String(removed_items)} ${
              removed_items.length > 1 ? "have" : "has"
            } been unpublished. Please consider to explore other memberships.`
          );

          setTimeout(() => {
            navigate(`/`);
          }, 1500);

          return;
        }

        NotificationManager.info(
          `${String(removed_items)} ${
            removed_items.length > 1 ? "have" : "has"
          } been unpublished and thus removed from your cart. Please review and complete your purchase again.`
        );

        return;
      }

      if (status === "DISQUALIFIED_COUPONS") {
        const not_permitted_coupon_ids = [];
        const not_permitted_plans = [];

        payloads.map((coupon) => {
          not_permitted_plans.push(coupon.plan);
          not_permitted_coupon_ids.push(coupon.coupon_id);
        });

        removeCoupons(not_permitted_coupon_ids);

        const itemsElement = not_permitted_plans.map((plan, index) => {
          return <li key={index}>{plan}</li>;
        });

        alertElement = (
          <div>
            <p>
              This coupon code does not applicable to the following cart items
              for your account. Thus, has been removed from the particular cart
              items. Please review and complete your purchase again.
            </p>
            <ul>{itemsElement}</ul>
          </div>
        );

        setShow(true);
        setModalContent(alertElement);

        return;
      }

      if (updateError) {
        const { message } = updateError;
        NotificationManager.error(message);
        return;
      }

      if (status === "CART_GRAND_TOTAL_ZERO") {
        window.location.replace(
          `/success?ppay_intent_client_secret=${payloads?.ppayIntent}`
        );
        return;
      }

      const { protocol, host } = window.location;
      let return_url = `${protocol}//${host}/success`;

      // ✅ Payment Intent
      if (status === "PAYMENT_CHECKOUT_INTENT") {
        elements.submit();

        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: return_url,
            payment_method_data: {
              billing_details: {
                name: obj.name,
                email: obj.email,
                address: {
                  country: obj.country_iso2,
                  city: null,
                  line1: null,
                  line2: null,
                  postal_code: null,
                  state: obj.state,
                },
                phone: null,
              },
            },
          },
        });

        if (error.type) {
          NotificationManager.error(error.message);

          return;
        } else {
          NotificationManager.error(
            "Something went wrong. Please try again later."
          );
          return;
        }
      }

      // ✅ Setup Intent
      if (status === "SETUP_CHECKOUT_INTENT") {
        elements.submit();

        const { error } = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: return_url,
            payment_method_data: {
              billing_details: {
                name: obj.name,
                email: obj.email,
                address: {
                  country: obj.country_iso2,
                  city: null,
                  line1: null,
                  line2: null,
                  postal_code: null,
                  state: obj.state,
                },
                phone: null,
              },
            },
          },
        });

        if (error.type) {
          NotificationManager.error(error.message);
          return;
        } else {
          NotificationManager.error(
            "Something went wrong. Please try again later."
          );
          return;
        }
      }

      // Missing logics
      if (status === "NOTHING") {
        console.log("..");
        return;
      }

      return;
    }

    console.log("Unknown payment gateway...");
    setIsLoading(false);
  };

  const createStripeCheckoutIntent = async () => {
    try {
      const plan_ids = selectedPlans.map((plan) => plan.price_id);
      if (!plan_ids.length) return;

      const loadStripe = await loadStripePublishableKey(paymentAccounts);
      if (!loadStripe) return;

      const { payment_account_id, load_stripe } = loadStripe;
      setStripePromise(load_stripe);
      setPaymentAccountId(payment_account_id);

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/stripe`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...obj,
            payment_account_id,
            plans: plan_ids,
            coupons: appliedCoupons.map((coupon) => coupon.coupon_id),
          }),
        }
      );

      const data = await response.json();
      const { error, payloads } = data;

      if (error) {
        const { message } = error;
        throw new Error(message);
      }
      const { id, client_secret } = payloads;

      setPaymentIntentId(id);
      setClientSecret(client_secret);

      return { payloads };
    } catch (error) {
      const { message } = error;
      throw NotificationManager.error(message);
    }
  };

  const updateStripeCheckoutIntent = async () => {
    try {
      const plan_ids = selectedPlans.map((plan) => plan.price_id);
      if (!plan_ids.length) return;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/stripe`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...obj,
            ...utm,
            payment_account_id: paymentAccountId,
            plans: plan_ids,
            coupons: appliedCoupons.map((coupon) => coupon.coupon_id),
            client_secret: clientSecret,
            payment_intent_id: paymentIntentId,
          }),
        }
      );

      const data = await response.json();
      const { error, status, payloads } = data;

      if (error) {
        const { message } = error;
        throw new Error(message);
      }

      return { status, payloads };
    } catch (error) {
      const { message } = error;
      throw NotificationManager.error(message);
    }
  };

  const createBankTransferCode = async () => {
    const plan_ids = selectedPlans.map((plan) => plan.price_id);
    if (!plan_ids.length) return;

    const paymentObj = {
      ...obj,
      ...utm,
      payment_account_id: paymentAccountId,
      plans: selectedPlans.map((plan) => plan.price_id),
      coupons: appliedCoupons.map((coupon) => coupon.coupon_id),
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/toyyibpay`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(paymentObj),
      }
    );

    const data = await response.json();
    const { error, status, payloads } = data;

    if (error) {
      NotificationManager.error(error?.message);
      return;
    }

    return { status, payloads };
  };

  // ✅ Create checkout intent based on email and name input
  useEffect(() => {
    const email = extractEmailFromURL();

    if (email) {
      setIsDisableInput(true);

      setObj((prevState) => ({
        ...prevState,
        email: email,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        email: true,
      }));
    }
  }, [obj.name, obj.email, isValid.name, isValid.email]);

  // ✅ Create checkout intent based on coupons
  useEffect(() => {
    const ccSettings = appliedCoupons.map(
      (coupon) => coupon.free_trial_is_cc_required
    );

    setCCSettings(ccSettings);

    if (ccSettings.includes(true) && ccSettings.includes(false)) {
      NotificationManager.warning(
        "Each cart item should be checkout separately due to the setting of the coupon code."
      );
      return;
    }

    // Credit card is required on checkout
    const is_required_cc = appliedCoupons.every(
      (coupon) => coupon.free_trial_is_cc_required && coupon.permission === 8
    );

    setIsRequiredCC(is_required_cc);
  }, [appliedCoupons]);

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      rules: {
        ".Label": {
          textTransform: "uppercase",
          fontWeight: "bold",
        },
      },
    },
  };

  let formIsValid = false;
  if (
    isValid.firstname &&
    isValid.lastname &&
    isValid.email &&
    isValid.country &&
    isValid.state &&
    isValid.phone_number &&
    isValid.authorized
  ) {
    formIsValid = true;
  }

  let enablePaymentSelection = false;
  if (isValid.firstname && isValid.lastname && isValid.email) {
    enablePaymentSelection = true;
  }

  if (
    !isNaN(grand_total) &&
    grand_total > 0 &&
    paymentVendor === "stripe" &&
    !clientSecret &&
    !paymentIntentId
  ) {
    formIsValid = false;
  }

  if (ccSettings.includes(true) && ccSettings.includes(false)) {
    formIsValid = false;
  }

  return (
    <>
      {show && (
        <AlertModal show={show} onClose={handleClose}>
          {modalContent}
        </AlertModal>
      )}

      <form>
        {/* Name */}
        {/* <div className='col-12 my-2'>
          <label htmlFor='name' className='form-label'>
            <span className='fw-bold text-uppercase'>Name</span>{" "}
            {!isValid.name && isTouched.name && (
              <span className='form-text text-danger'>🚨 Required!</span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='name'
            value={obj.name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </div> */}

        {/* First Name */}
        <div className='col-12 my-2'>
          <label htmlFor='firstname' className='form-label'>
            <span className='fw-bold text-uppercase'>First Name</span>{" "}
            {!isValid.firstname && isTouched.firstname && (
              <span className='form-text text-danger'>🚨 Required!</span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='firstname'
            value={obj.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />

          <div className='form-text'>Note: Please fill in your given name.</div>
        </div>

        {/* Last Name */}
        <div className='col-12 my-2'>
          <label htmlFor='lastname' className='form-label'>
            <span className='fw-bold text-uppercase'>Last Name</span>{" "}
            {!isValid.lastname && isTouched.lastname && (
              <span className='form-text text-danger'>🚨 Required!</span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='lastname'
            value={obj.lastname}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />

          <div className='form-text'>
            Note: Please fill in your surname / family name.
          </div>
        </div>

        {/* Email Address */}
        <div className='col-12 my-2'>
          <label htmlFor='email' className='form-label'>
            <span className='fw-bold text-uppercase'>Email Address</span>{" "}
            {isEmailConPatter && !isValid.email && isTouched.email && (
              <span className='form-text text-danger'>
                🚨 Do you actually mean .com?
              </span>
            )}
            {!isEmailConPatter && !isValid.email && isTouched.email && (
              <span className='form-text text-danger'>
                🚨 A valid email address is required!
              </span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='email'
            value={obj.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisableInput || disabled}
          />
        </div>

        {/* Country / Region */}
        <div className='col-md-12 mb-3'>
          <label
            htmlFor='country'
            className='form-label fw-bold text-uppercase'
          >
            Country / Region
          </label>

          <Select
            options={countryOptions}
            placeholder='Select your country / region'
            name='country'
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "country")
            }
            isDisabled={disabled}
          />
        </div>

        {/* State / County */}
        {stateOptions.length > 0 && (
          <div className='col-md-12 mb-3'>
            <label
              htmlFor='country'
              className='form-label fw-bold text-uppercase'
            >
              State / County
            </label>

            <Select
              options={stateOptions}
              placeholder='Select your state / county'
              name='state'
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "state")
              }
              ref={stateRef}
              isDisabled={disabled}
            />
          </div>
        )}

        {/* Phone */}
        <div className='col-md-12'>
          {/* Phone Code */}
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <label
                htmlFor='phone_code'
                className='form-label fw-bold text-uppercase'
              >
                Country Code
              </label>

              <p className='form-control form-control-lg bg-light'>
                {obj.phone_code}
              </p>
            </div>

            {/* Contact Number */}
            <div className='col-md-9 mb-3'>
              <label htmlFor='phone_number' className='form-label'>
                <span className='fw-bold text-uppercase'>Contact Number</span>{" "}
                {!isValid.phone_number && isTouched.phone_number && (
                  <span className='form-text text-danger'>🚨 Required!</span>
                )}
              </label>

              <div className='input-group'>
                <NumericFormat
                  displayType='input'
                  className='form-control form-control-lg'
                  name='phone_number'
                  decimalScale={0}
                  valueIsNumericString={true}
                  value={obj.phone_number}
                  onValueChange={(values) =>
                    handleNumericChange(values, "phone_number")
                  }
                  onBlur={() => handleNumericBlur("phone_number")}
                  disabled={disabled || !isValid.country}
                  maxLength={max_phone_length}
                />
              </div>

              <div className='form-text'>
                Note: Please fill your contact number without country code.
              </div>
            </div>
          </div>
        </div>

        {/* Select payment types */}
        {!isNaN(grand_total) && grand_total > 0 && (
          <div className='col-md-12 mb-3'>
            <label
              htmlFor='payment_account'
              className='form-label fw-bold text-uppercase'
            >
              How do you want to pay?
            </label>

            {paymentAccounts.length &&
              paymentAccounts.map((account) => {
                return (
                  <div className='form-check border my-2 py-3' key={account.id}>
                    <div className='mx-3'>
                      <input
                        className='form-check-input border border-dark'
                        type='radio'
                        name='payment_type'
                        value={account.vendor}
                        onClick={(e) =>
                          handleSelectPaymentType(e, {
                            vendor: account.vendor,
                            id: account.id,
                          })
                        }
                        disabled={!enablePaymentSelection}
                      />

                      <label
                        className='form-check-label'
                        htmlFor='flexRadioDefault1'
                      >
                        {account?.label ? account?.label : "Payment Gateway"}
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {/* Card Element */}
        {!isNaN(grand_total) &&
          grand_total > 0 &&
          paymentVendor === "stripe" && (
            <div className='col-md-12 mb-3'>
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CardElement onCardElementChange={cardElementChangeHandler} />
                </Elements>
              )}
            </div>
          )}

        {grand_total === 0 && isRequiredCC && paymentVendor === "stripe" && (
          <div className='col-md-12 mb-3'>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CardElement onCardElementChange={cardElementChangeHandler} />
              </Elements>
            )}
          </div>
        )}

        {/* Check */}
        <div className='col-md-12 mb-3 form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            id='authorizedCheck'
            required
            onClick={handleCheck}
          />
          <label className='form-check-label' htmlFor='authorizedCheck'>
            I have read and agree to Piranha Profits' Terms of Use and Privacy
            Policy.
          </label>
        </div>

        {/* Button */}
        <div className='col-md-12 mb-3 d-grid gap-2'>
          <button
            className='btn btn-lg btn-block fw-bold rounded-pill text-white'
            style={{ backgroundColor: "#3CAD66" }}
            id='submit'
            disabled={isLoading || !formIsValid || disabled}
            onClick={paymentHandler}
          >
            {isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  aria-hidden='true'
                ></span>
                <span role='status'>Processing...</span>
              </>
            ) : (
              <span id='button-text'>Complete purchase</span>
            )}
          </button>
        </div>

        <div
          className='col-12 my-2 text-center m-2 fw-normal'
          style={{ fontSize: "12px" }}
        >
          <EncryptionIcon />
          Encrypted & secure payments
        </div>
      </form>
    </>
  );
};

export default PaymentForm;
