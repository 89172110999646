import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Link, Outlet } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import ReactPixel from "react-facebook-pixel";
import { NotificationManager } from "react-notifications";
import { formatPrice } from "../../utils/formatValues";
import StoreNavigation from "../../components/Store/StoreNavigation";
import { addItem, clearCart } from "../../store/cartSlice";
import StoreFooter from "../../components/Store/StoreFooter";
import BasicSpinner from "../../components/UI/Spinner";

const Storefront = () => {
  const dispatch = useDispatch();
  const [payloads, setPayloads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetch_fb_pixels = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/settings`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      const { error, payloads } = data;

      if (error) {
        return { error };
      }

      if (!payloads) return null;

      return payloads;
    };

    const initiate_fb_tracking = async () => {
      const { fb_pixel, fb_pixel_test_code } = await fetch_fb_pixels();
      let metadata = {};

      if (Boolean(fb_pixel_test_code)) {
        metadata.test_event_code = fb_pixel_test_code;
      }

      if (Boolean(fb_pixel)) {
        ReactPixel.init(fb_pixel);
        ReactPixel.pageView(metadata); // For tracking page view
      }
    };

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/plans`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        const { error, payloads } = data;

        if (error) {
          const { message } = error;
          return NotificationManager.error(message);
        }

        if (!payloads.length) dispatch(clearCart());

        initiate_fb_tracking();
        setPayloads(payloads);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const addToCartHandler = (plan) => {
    dispatch(addItem(plan));
  };

  let priceElements;

  if (payloads.length) {
    priceElements = payloads.map((payload, index) => {
      const { name, currency, listing_price, selling_price, slug, banner } =
        payload;
      return (
        <Card className='m-3 p-3' style={{ width: "25rem" }} key={index}>
          <Card.Img
            variant='top'
            src={`${process.env.REACT_APP_AWS_BUCKET_ENDPOINT}/plans/${banner}`}
            alt='piranha profits banner'
            width={300}
            height={300}
          />
          <Card.Body>
            <Card.Title className='text-center'>{name}</Card.Title>
            <Card.Title className='text-center'>
              {/* <span className='text-decoration-line-through'>
                {currency.toUpperCase()} {formatPrice(listing_price)}
              </span>
              <br /> */}
              {currency.toUpperCase()} {formatPrice(selling_price)}
            </Card.Title>
          </Card.Body>

          <Card.Body className='text-center p-0'>
            <Link
              to={`/${process.env.REACT_APP_PLAN_PATHNAME}/${slug}`}
              className='btn btn-success p-3 fw-bolder m-2'
            >
              CHECKOUT NOW
            </Link>
          </Card.Body>
        </Card>
      );
    });
  } else {
    priceElements = (
      <Container className='container-fluid'>
        <Row className='row justify-content-center align-items-center vh-100'>
          <Col className='col'>
            <p className='text-center'>
              We are settings up our store at the moment.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <StoreNavigation />

      <Container>
        {isLoading && (
          <div className='text-center my-3'>
            <BasicSpinner />
          </div>
        )}

        <Row xs={1} md={2} className='mt-3 g-4' id='storefrontRow'>
          {!isLoading && priceElements}
        </Row>
      </Container>

      <Outlet />

      <StoreFooter />
    </>
  );
};

export default Storefront;
